var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"admin-knowledge-base-category"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('good-data-table',{ref:"baseRef",attrs:{"button-label":_vm.$t('Add Knowledge Base'),"columns":_vm.baseColumns,"total-column":'response.responseData.total',"data-column":'response.responseData.data',"api-endpoint":'/admin/knowledge/list'},on:{"add-new-info":_vm.showAddModal}})],1)],1),_c('b-modal',{attrs:{"id":"knowledgeBaseDetailData","no-close-on-backdrop":true,"title":_vm.dataInfo && _vm.dataInfo.id ? 'Edit Knowledge Base' : 'Add Knowledge Base',"size":"lg","ok-only":"","ok-title":_vm.$t('Save Changes')},on:{"hidden":function($event){_vm.showItemsModal = false},"ok":_vm.saveData},model:{value:(_vm.showItemsModal),callback:function ($$v) {_vm.showItemsModal=$$v},expression:"showItemsModal"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',{staticClass:"form-extra-label"},[_vm._v(_vm._s(_vm.$t('Category')))]),_c('b-form-select',{attrs:{"options":_vm.knowledgeCategoryOptions},model:{value:(_vm.dataInfo.category_id),callback:function ($$v) {_vm.$set(_vm.dataInfo, "category_id", $$v)},expression:"dataInfo.category_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Title')}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataInfo.title),callback:function ($$v) {_vm.$set(_vm.dataInfo, "title", $$v)},expression:"dataInfo.title"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Status')}},[_c('b-form-select',{attrs:{"options":[
              {
                text: 'Active',
                value: '1',
              },
              {
                text: 'Inactive',
                value: '0',
              } ]},model:{value:(_vm.dataInfo.status),callback:function ($$v) {_vm.$set(_vm.dataInfo, "status", $$v)},expression:"dataInfo.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Picture')}},[_c('image-input',{attrs:{"default-image":_vm.dataInfo.picture},on:{"input-file":_vm.inputFile}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Description')}},[_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.dataInfo.description),callback:function ($$v) {_vm.$set(_vm.dataInfo, "description", $$v)},expression:"dataInfo.description"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }